@import "colors.scss";

table {
    border-collapse: collapse;
}

/* row groups */

table tbody tr.group-title {
    cursor: pointer;
}

table tbody tr.group-title td {
    border-left: none;
    border-right: none;
}

table tbody tr.group-title {
    background-color: $light;
    color: $text-color-light;
}

/* table classes */

table.alternate tbody tr:nth-child(odd):not(.group-title) {
    background-color: $white;
}

table.alternate tbody tr:nth-child(even):not(.group-title) {
    background-color: #efefef;
}

table.hover tbody tr:hover:not(.group-title) {
    background-color: $light;
    color: $text-color-light;
}

table.hidden {
    display: none;
}

/*
Original from: http://www.imaputz.com/cssStuff/bigFourVersion.html
under http://creativecommons.org/licenses/by-sa/2.0/

Cleaned and adapted by Borm Informatik AG
*/

/* set table header to a fixed position. WinIE 6.x only                                       */
/* In WinIE 6.x, any element with a position property set to relative and is a child of       */
/* an element that has an overflow property set, the relative value translates into fixed.    */
/* Ex: parent element DIV with a class of tableContainer has an overflow property set to auto */
table thead.table-fixed-header tr {
    position: relative;
}

/* set THEAD element to have block level attributes. All other non-IE browsers            */
/* this enables overflow to work on TBODY element. All other non-IE, non-Mozilla browsers */
table thead.table-fixed-header tr {
    display: block;
}

/* define the table content to be scrollable                                              */
/* set TBODY element to have block level attributes. All other non-IE browsers            */
/* this enables overflow to work on TBODY element. All other non-IE, non-Mozilla browsers */
/* induced side effect is that child TDs no longer accept width: auto                     */
table tbody.table-scroll-body {
    display: block;
    overflow: auto;
    width: 100%;
    margin-top: -1px;
}

table tbody.table-scroll-body {
    border-bottom: 1px solid $default-border;
}

table tbody.table-scroll-body tr td:last-child {
    border-right: none;
}